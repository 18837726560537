import { Color } from '@mui/material'

const lichen: Partial<Color> = {
  100: '#E4E6DC',
  200: '#CDD0BD',
  300: '#B6BB9F',
  400: '#9FA682',
  500: '#878F65',
  600: '#6B7150',
  700: '#4F543B',
  800: '#333626',
  900: '#1C1E15',
}

export default lichen
