import { Input } from "@nextui-org/react"
import { useField, useFormikContext } from "formik"
import { FC, useMemo } from "react";

interface FormTextInputProps {
  name: string;
    placeholder?: string;
    type?: string;
}

const FormTextInput: FC<FormTextInputProps> = ({ name, placeholder, type }) => {

    const { isSubmitting } = useFormikContext();
    const [field, meta] = useField(name);

    const isError = useMemo(() => meta.touched && meta.error ? 'error' : 'primary', [meta]);

    return (<Input
        underlined
        name={name}
        type={type}
        onChange={field.onChange}
        onBlur={field.onBlur}
        labelPlaceholder={placeholder}
        disabled={isSubmitting}
        status={ isError }
        color={isError}
        animated={true}
        helperColor={ isError }
        helperText={isError === 'error' ? meta.error : ''}
        fullWidth={true}
    />)
}

FormTextInput.defaultProps = {
    placeholder: '',
    type: 'text',
}

export default FormTextInput;