import { Color } from '@mui/material'

const lime: Partial<Color> = {
  100: '#EEF9C8',
  200: '#E1F39B',
  300: '#D3EE6D',
  400: '#C8EA48',
  500: '#BAE41B',
  600: '#95B715',
  700: '#708910',
  800: '#4A5B0B',
  900: '#252E05',
}

export default lime
