'use client'

import {
  checkboxClasses,
  buttonClasses,
} from '@mui/material'
import Fade from '@mui/material/Fade'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { createTheme } from '@mui/material/styles'
import { alpha } from '@mui/material/styles'
import AppIcon from '../components/AppIcon'
import charcoal from './colors/charcoal'
import cream from './colors/cream'
import forest from './colors/forest'
import golden from './colors/golden'
import lichen from './colors/lichen'
import lime from './colors/lime'

const { palette, breakpoints, typography, shadows } = createTheme()

const DM_SANS_FONT = '"DM Sans", sans-serif'
const SOURCE_SANS_FONT = '"Source Sans 3", sans-serif'
const DATE_FORMAT = 'MM-DD-YYYY'

shadows[1] = '0px 0px 12px 0px rgba(0, 0, 0, 0.05)'
shadows[2] = '0px 4px 8px 0px rgba(0, 0, 0, 0.08)'

const theme = createTheme({
  palette: {
    primary: {
      ...forest,
      light: forest[100]!,
      main: forest[700]!,
      dark: forest[800]!,
    },
    secondary: {
      ...cream,
      light: cream[600]!,
      main: cream[800]!,
      dark: cream[900]!,
    },
    background: {
      default: '#F7F7F8',
    },
    divider: '#D3D4D5',
    lichen: palette.augmentColor({
      color: lichen,
      name: 'lichen',
      lightShade: 300,
    }),
    golden: palette.augmentColor({
      color: golden,
      name: 'golden',
    }),
    charcoal: palette.augmentColor({
      color: charcoal,
      name: 'charcoal',
    }),
    lime: palette.augmentColor({
      color: lime,
      name: 'lime',
    }),
    white: {
      light: '#F5F5F5',
      main: '#FFFFFF',
      dark: '#F0F0F0',
      contrastText: '#2E363D',
    },
    border: {
      main: '#D3D4D5',
    },
    error: {
      light: '#FFF4F4',
      main: '#DE6159',
      dark: '#BA2F25',
    },
    warning: {
      main: '#FFA46B',
    },
    success: {
      main: '#B6D347',
    },
    info: {
      main: '#37635F',
    },
    text: {
      primary1: forest[900],
      primary2: forest[800],
      primary3: forest[700],
      secondary: charcoal[700],
    },
    stroke: {
      primary1: forest[700],
      primary2: forest[200],
      secondary: charcoal[300],
      exceptions1: golden[400],
    },
    surface: {
      primary1: forest[800],
      primary2: forest[700],
      primary3: forest[600],
      primary4: forest[400],
      secondary1: forest[100],
      secondary2: forest[200],
      secondary3: forest[300],
      secondary4: forest[400],
      background: charcoal[200],
      templates: '#FFFFFF',
      exceptions: cream[600],
    },
  },
  typography: {
    fontFamily: DM_SANS_FONT,
    h1: {
      fontSize: typography.pxToRem(32),
      fontWeight: 500,
      color: palette.primary[800],
      lineHeight: typography.pxToRem(45),
      letterSpacing: typography.pxToRem(0.2),
    },
    h2: {
      fontSize: typography.pxToRem(24),
      fontWeight: 500,
      lineHeight: typography.pxToRem(32),
      letterSpacing: typography.pxToRem(0.2),
    },
    h3: {
      fontSize: typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: typography.pxToRem(28),
      letterSpacing: typography.pxToRem(0.2),
    },
    h4: {
      fontSize: typography.pxToRem(18),
      fontWeight: 500,
      lineHeight: typography.pxToRem(24),
      letterSpacing: typography.pxToRem(0.2),
    },
    h5: {
      fontSize: typography.pxToRem(16),
      fontWeight: 500,
      lineHeight: typography.pxToRem(22),
      letterSpacing: typography.pxToRem(0.2),
    },
    h6: {
      fontSize: typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: typography.pxToRem(20),
      letterSpacing: typography.pxToRem(0.2),
    },
    h7: {
      fontSize: typography.pxToRem(12),
      fontWeight: 500,
      lineHeight: typography.pxToRem(16),
      letterSpacing: typography.pxToRem(0.2),
    },
    body1: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(16),
      lineHeight: typography.pxToRem(24),
      fontWeight: 400,
    },
    body2: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(14),
      lineHeight: typography.pxToRem(20),
      fontWeight: 400,
    },
    body3: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(16),
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(14),
      color: '#6C7176',
      fontWeight: 400,
    },
    button: {
      fontFamily: SOURCE_SANS_FONT,
      fontWeight: 600,
      fontSize: typography.pxToRem(16),
      lineHeight: typography.pxToRem(24),
      letterSpacing: typography.pxToRem(0.5),
      textTransform: 'none',
    },
    overline: {
      fontFamily: SOURCE_SANS_FONT,
      color: '#81868A',
      fontSize: typography.pxToRem(14),
      fontWeight: 600,
      lineHeight: typography.pxToRem(20),
      letterSpacing: typography.pxToRem(0.84),
      textTransform: 'uppercase',
    },
    label1: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(16),
      fontWeight: 600,
      lineHeight: typography.pxToRem(24),
      letterSpacing: typography.pxToRem(0.08),
    },
    label2: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(14),
      fontWeight: 600,
      lineHeight: typography.pxToRem(20),
      letterSpacing: typography.pxToRem(0.07),
    },
    label3: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(12),
      fontWeight: 600,
      lineHeight: typography.pxToRem(16),
      letterSpacing: typography.pxToRem(0.06),
    },
    section: {
      fontFamily: SOURCE_SANS_FONT,
      fontSize: typography.pxToRem(14),
      fontWeight: 600,
      lineHeight: typography.pxToRem(20),
      letterSpacing: typography.pxToRem(0.06),
      textTransform: 'uppercase',
    },
    subheader: {
      fontFamily: DM_SANS_FONT,
      fontSize: typography.pxToRem(18),
      fontWeight: 400,
      lineHeight: typography.pxToRem(24),
      letterSpacing: typography.pxToRem(0.2),
    },
  },
  shadows,
  mixins: {
    toolbar: {
      minHeight: typography.pxToRem(63),
      [breakpoints.up('xs')]: {
        '@media (orientation: landscape)': {
          minHeight: typography.pxToRem(63),
        },
      },
      [breakpoints.up('sm')]: {
        minHeight: typography.pxToRem(63),
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
          '&:disabled': {
            backgroundColor: theme.palette.primary[700],
            color: alpha(theme.palette.white.main, 0.5),
            '& > *': {
              color: alpha(theme.palette.white.main, 0.5),
            },
          },
          ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                backgroundColor: theme.palette.surface.primary2,
                color: theme.palette.surface.templates,
                '&:disabled': {
                  backgroundColor: theme.palette.surface.primary2,
                  color: alpha(theme.palette.surface.templates!, 0.5),
                  '& > *': {
                    color: alpha(theme.palette.surface.templates!, 0.5),
                  },
                },
                '&:hover': {
                  backgroundColor: theme.palette.surface.primary3,
                },
                '&:active': {
                  backgroundColor: theme.palette.surface.primary4,
                },
              }),
          ...(ownerState.variant === 'contained' &&
              ownerState.color === 'secondary' && {
                backgroundColor: theme.palette.surface.secondary2,
                color: theme.palette.surface.primary1,
                '&:disabled': {
                  backgroundColor: theme.palette.surface.secondary2,
                  color: alpha(theme.palette.text.primary1, 0.3),
                  '& > *': {
                    color: alpha(theme.palette.text.primary1, 0.3),
                  },
                },
                '&:hover': {
                  backgroundColor: theme.palette.surface.secondary3,
                },
                '&:active': {
                  backgroundColor: theme.palette.surface.secondary4,
                },
              }),
          ...(ownerState.variant === 'text' && {
            color: theme.palette.primary[800],
            '&:hover': {
              color: theme.palette.primary[700],
            },
            '&:active': {
              color: theme.palette.primary[600],
            },
            '&:disabled': {
              backgroundColor: 'unset',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            border: `1px solid ${theme.palette.primary[200]}`,
            '&:hover': {
              background: theme.palette.primary[200],
              '& > *': {
                color: theme.palette.primary[700],
              },
            },
            '&:active': {
              background: theme.palette.primary[700],
              borderColor: theme.palette.primary[700],
              '& > *': {
                color: theme.palette.white.main,
              },
            },
            '&:disabled': {
              background: theme.palette.white.main,
              opacity: 0.5,
              borderColor: theme.palette.charcoal[200],
              '& > *': {
                color: theme.palette.primary[800],
              },
              '&:hover': {
                background: 'none',
              },
            },
          }),
        }),
        endIcon: {
          marginRight: 'unset',
          marginLeft: 6,
        },
        startIcon: {
          marginLeft: 'unset',
          marginRight: 6,
        },
        sizeSmall: ({ theme }) => ({
          padding: theme.spacing(0.75, 1.25),
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(20),
          letterSpacing: theme.typography.pxToRem(0.07),
          height: 32,
        }),
        sizeMedium: ({ theme }) => ({
          padding: theme.spacing(1, 1.5),
          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.typography.pxToRem(24),
          letterSpacing: typography.pxToRem(0.08),
          height: 40,
        }),
        sizeLarge: ({ theme }) => ({
          padding: theme.spacing(1.5, 2),
          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.typography.pxToRem(24),
          letterSpacing: typography.pxToRem(0.08),
          height: 48,
        }),
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${[buttonClasses.outlined]}`]: {
            backgroundColor: theme.palette.surface.templates,
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: () => ({
          borderBottomWidth: 1,
        }),
      },
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const hoverColor =
              ownerState.color === 'secondary'
                  ? theme.palette.secondary.dark
                  : theme.palette.charcoal[50]

          const mainColor =
              ownerState.color === 'secondary'
                  ? theme.palette.secondary.main
                  : theme.palette.surface.secondary1

          return {
            color: theme.palette.text.primary1,
            padding: theme.spacing(1, 1.5),
            borderRadius: 6,
            '&:hover': {
              backgroundColor: hoverColor,
            },
            '&.Mui-selected': {
              backgroundColor: mainColor,
              '&:hover': {
                backgroundColor: hoverColor,
              },
              [`& .${listItemIconClasses.root}`]: {
                color: theme.palette.text.primary1,
              },
            },
          }
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        primary: {
          fontWeight: 500,
          fontFamily: DM_SANS_FONT,
          fontSize: typography.pxToRem(14),
          lineHeight: typography.pxToRem(24),
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: 'initial',
          paddingInlineEnd: theme.spacing(1.5),
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 2, 1, 3),
          minHeight: 'initial',
        }),
        expandIconWrapper: ({ theme }) => ({
          marginInlineEnd: theme.spacing(1),
        }),
        content: {
          margin: 0,
          '& .MuiTypography-root': {
            fontFamily: DM_SANS_FONT,
            fontWeight: 500,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        icon: <AppIcon name="checkBoxBlank" width={24} height={24} />,
        checkedIcon: <AppIcon name="checkBoxChecked" width={24} height={24} />,
      },
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary' } }) => ({
          padding: 0,
          margin: 0,
          ...(color !== 'default' && {
            [`&:not(.${checkboxClasses.checked}, .${checkboxClasses.indeterminate})`]:
                {
                  color: theme.palette.charcoal[400],
                  '&:hover': {
                    rect: {
                      fill: theme.palette.charcoal[200],
                    },
                  },
                  '&:active': {
                    rect: {
                      fill: theme.palette.charcoal[300],
                    },
                  },
                  [`&.${checkboxClasses.disabled}`]: {
                    color: theme.palette.charcoal[300],
                  },
                },
            [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]:
                {
                  color: theme.palette[color][700],
                  '&:focus': {
                    color: theme.palette[color][700],
                  },
                  '&:hover': {
                    color: theme.palette[color][600],
                  },
                  '&:active': {
                    color: theme.palette[color][400],
                  },
                  [`&.${checkboxClasses.disabled}`]: {
                    color: theme.palette.charcoal[400],
                  },
                },
          }),
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.5),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        label: ({ theme }) => ({
          padding: theme.spacing(1, 2),
          fontWeight: 600,
        }),
        labelSmall: {
          fontFamily: SOURCE_SANS_FONT,
        },
        colorPrimary: ({ theme }) => ({
          background: theme.palette.primary[100],
          color: theme.palette.primary[900],
        }),
        sizeSmall: ({ theme }) => ({
          fontSize: theme.typography.pxToRem(12),
        }),
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(5),
        }),
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'default' } }) => ({
          backgroundColor:
              color === 'primary' ? theme.palette.primary.dark : undefined,
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: SOURCE_SANS_FONT,
          fontWeight: 600,
          lineHeight: theme.typography.pxToRem(20),
          letterSpacing: theme.typography.pxToRem(0.7),
          textTransform: 'uppercase',
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => ({
          rowGap: theme.spacing(1),
          columnGap: theme.spacing(2),
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          [`input[placeholder="${DATE_FORMAT}"][value="${DATE_FORMAT}"]`]: {
            color: theme.palette.charcoal[600],
          },
        }),
      },
      variants: [
        {
          props: { layout: 'inline' },
          style: {
            flexDirection: 'row',
            alignItems: 'center',
            '& .MuiInputBase-root': {
              width: 'initial',
              flex: 1,
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.charcoal[200]}`,
          borderRadius: 8,
          '&:not(.Mui-disabled):hover': {
            borderColor: theme.palette.primary[700],
          },
          '&.Mui-focused': {
            borderColor: theme.palette.primary[700],
            background: theme.palette.primary[100],
          },
        }),
        input: ({ theme }) => ({
          padding: theme.spacing(1, 2),
          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.typography.pxToRem(24),
          fontWeight: 400,
          height: 'initial',
        }),
        notchedOutline: {
          display: 'none',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.charcoal[600],
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(20),
          fontWeight: 600,
          position: 'static',
          transform: 'initial',
          maxWidth: 'initial',
          zIndex: 'initial',
          textTransform: 'uppercase',
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        listbox: ({ theme }) => ({
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: 'unset',
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: theme.palette.surface.secondary1,
          },
        }),
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 1,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
      styleOverrides: {
        paper: ({ theme }) => ({
          border: `1px solid ${theme.palette.charcoal[200]}`,
          borderRadius: 8,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 2, 1, 2),
          '& .MuiListItemText-primary': {
            fontFamily: SOURCE_SANS_FONT,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            fontWeight: 400,
          },
          '&.Mui-selected': {
            backgroundColor: 'unset',
            '& .MuiListItemText-primary': {
              fontWeight: 600,
              color: theme.palette.primary[600],
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.primary[100],
          },
        }),
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: ({ theme }) => ({
          right: theme.spacing(1),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paper': {
            borderRadius: 16,
          },
        },
      },
      defaultProps: {
        PaperProps: {
          elevation: 1,
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h4',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(5, 5, 3, 5),
          borderBottom: '1px solid #E4E6DC',
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(5),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2.5, 5, 5, 5),
        }),
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiStep-root.MuiStep-horizontal': {
            '&:first-child': {
              marginLeft: 0,
              '.MuiStepLabel-root, .MuiStepLabel-label': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              },
            },
            '&:nth-child(2)': {
              '.MuiStepConnector-root': {
                left: 'calc(-100% + 16px)',
              },
            },
            '&:last-child': {
              paddingRight: theme.spacing(3),
              '.MuiStepLabel-root, .MuiStepLabel-label': {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              },
              '.MuiStepConnector-root': {
                right: 16,
              },
            },
          },
          '& .MuiStepLabel-root.MuiStepLabel-vertical': {
            padding: 0,
          },
          '& .MuiStepConnector-root.MuiStepConnector-vertical': {
            marginLeft: 7,
          },
          '& .MuiStepConnector-root .MuiStepConnector-lineVertical': {
            borderLeftWidth: 2,
            height: 37,
          },
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          fontFamily: DM_SANS_FONT,
          lineHeight: theme.typography.pxToRem(16),
          letterSpacing: theme.typography.pxToRem(0.2),
          whiteSpace: 'nowrap',
          fontWeight: 500,
          fontSize: theme.typography.pxToRem(12),
          color: theme.palette.primary[900],
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: theme.spacing(1),
          },
        }),
        iconContainer: ({ theme }) => ({
          div: {
            height: theme.spacing(2),
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.error.main,
          fontFamily: SOURCE_SANS_FONT,
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(20),
          margin: 0,
          marginTop: '-6px',
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.white.main,
          color: theme.palette.primary.dark,
          boxShadow: theme.shadows[2],
          border: `1px solid ${theme.palette.charcoal[300]}`,
          borderRadius: 8,
        }),
        arrow: ({ theme }) => ({
          '&::before': {
            backgroundColor: theme.palette.white.main,
            border: `1px solid ${theme.palette.charcoal[300]}`,
          },
        }),
      },
      defaultProps: {
        TransitionComponent: Fade,
        TransitionProps: {
          timeout: 300,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: 40,
          height: 24,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '200ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary[700],
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              border: `1px solid ${theme.palette.primary[900]}`,
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: theme.palette.charcoal[400],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
          },
          '& .MuiSwitch-track': {
            borderRadius: 24 / 2,
            backgroundColor: theme.palette.charcoal[400],
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: () => ({
          fontSize: 16,
        }),
        li: () => ({
          fontWeight: 600,
        }),
      },
    },
  },
})

export default theme
