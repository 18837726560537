import React from 'react';
import ReactDOM from 'react-dom/client';
import {PostHogProvider} from 'posthog-js/react'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PostHogConfig} from "posthog-js";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


declare global {
  interface Window {
    pakSetting?: {
      postHog: {
        enabled: boolean,
        apiKey: string,
        host: string,
      },
    }
  }
}

let app = <App/>
if (window.pakSetting?.postHog.enabled) {
  const options: Partial<PostHogConfig> = {
    api_host: window.pakSetting.postHog.host,
    person_profiles: 'identified_only',
  }
  app = (
    <PostHogProvider
      apiKey={window.pakSetting.postHog.apiKey}
      options={options}
    >
      {app}
    </PostHogProvider>
  )
} else {
  app = (
    <PostHogProvider>
      {app}
    </PostHogProvider>
  )
}

root.render(
  <React.StrictMode>
    {app}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
