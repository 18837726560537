import React, {useEffect, useState} from 'react';
import {Stack, Typography, IconButton, useTheme, Button, CircularProgress, useMediaQuery} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';
import {useFormikContext} from 'formik';
import {AuthFormFields, AuthUIMode} from '..';
import AppIcon from '../../../components/AppIcon';
import GeneralError from "../../error/general-error";
import {redirectPage, sleep} from "../../../utils";
import usePostHogCapture from "../../../hooks/usePostHogCapture";

interface EmailConfirmationProps {
  mode: AuthUIMode;
}

const subtitle = {
  signup: 'Click on the link to verify your account.',
  login: 'Click on the link to sign in instantly.',
};


const EXCHANGE_TOKEN_URL = '/auth/login/exchange_token';

const EmailConfirmation: React.FC<EmailConfirmationProps> = ({mode}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const formik = useFormikContext<AuthFormFields>();
  const postHogCapture = usePostHogCapture()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [error, setError] = useState<string>()

  useEffect(() => {
    let interval: NodeJS.Timer
    const exchangeToken = async () => {
      const response = await fetch(EXCHANGE_TOKEN_URL, {
        method: 'POST',
        credentials: 'same-origin'
      });

      if (response.status === 200) {
        clearInterval(interval)
        postHogCapture('user_logged_in', {
          email: formik.values.email
        }, {
          send_instantly: true,
          skip_client_rate_limiting: true
        })
        const body = await response.json()
        await sleep(1000)
        redirectPage(body.redirectUri)
      } else if (response.status === 410) {
        navigate('/error?code=invalid_token')
      } else if (!response.ok) {
        let error
        try {
          const body = await response.json()
          if (body.message) {
            error = body.message
          }
        } catch (e) {
          console.error(e)
        }
        setError(error ?? 'Something went wrong! Please try again later.')
      }
    }

    if (!error) {
      interval = setInterval(exchangeToken, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [error, formik.values.email, postHogCapture, navigate])

  if (error)
    return (
      <GeneralError
        title='Whoops!'
        subtitle={error}
      />
    )

  if (isSmall) {
    return (
      <Stack padding={3} paddingTop={5} spacing={3} alignItems='center'>
        <CircularProgress size={64}/>
        <Stack spacing={2} alignItems='center'>
          <Typography variant='h4'>Check your e-mail</Typography>
          <Typography variant='body2' color='text.secondary' textAlign='center'>
            We’ve sent a confirmation link to {formik.values.email}. Click on the link to verify your account.
          </Typography>
        </Stack>
        <Typography variant='body2' color='text.secondary'>
          Don’t see the e-mail? Check your spam folder.
        </Typography>
        <Button
          endIcon={
            <AppIcon
              name='refresh'
              width={24}
              height={24}
            />
          }
          onClick={() => formik.handleSubmit()}
        >
          Resend e-mail
        </Button>
      </Stack>
    )
  }

  const header = (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      paddingX={5}
      paddingTop={5}
    >
      <AppIcon
        name='pakkedLogoFull'
        width={160}
        height={56}
        viewBox='0 0 160 56'
      />

      <IconButton onClick={() => navigate('/')}>
        <CloseIcon/>
      </IconButton>
    </Stack>
  );

  const text = (
    <Stack textAlign='center' spacing={2} maxWidth={456} alignSelf='center'>
      <Typography variant='h1'>Check your e-mail</Typography>
      <Typography variant='body1' color={theme.palette.charcoal[700]}>
        We’ve sent a confirmation link to {formik.values.email}. Click on the link to verify your account.
      </Typography>
    </Stack>
  );

  const actionArea = (
    <Stack spacing={3} textAlign='center' alignItems='center'>
      <Button
        endIcon={
          <AppIcon
            name='refresh'
            width={24}
            height={24}
            sx={{color: theme.palette.primary[700]}}
          />
        }
        onClick={() => formik.handleSubmit()}
      >
        Resend e-mail
      </Button>
      <Typography variant='body2' color={theme.palette.charcoal[700]}>
        Don’t see the e-mail? Check your spam folder.
      </Typography>
    </Stack>
  );

  return (
    <Stack spacing={5}>
      {header}
      <CircularProgress size={64} sx={{alignSelf: 'center'}}/>
      {text}
      {actionArea}
    </Stack>
  );
};

export default EmailConfirmation;
