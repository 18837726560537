import { Color } from '@mui/material'

const charcoal: Partial<Color> = {
  50: '#F8F9FA',
  100: '#F4F4F5',
  200: '#E9EAEA',
  300: '#D3D4D5',
  400: '#BDBFC1',
  500: '#A7AAAC',
  600: '#919597',
  700: '#81868A',
  800: '#6C7176',
  900: '#575E63',
  A700: '#2E363D',
}

export default charcoal
