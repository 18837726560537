import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as ApprovedSvg } from '../assets/icons/approved.svg';
import { ReactComponent as PakkedLogoSvg } from '../assets/icons/pakked-logo.svg';
import { ReactComponent as GoogleSvg } from '../assets/icons/google.svg';
import { ReactComponent as MicrosoftSvg } from '../assets/icons/microsoft.svg';
import { ReactComponent as RefreshSvg } from '../assets/icons/refresh.svg';
import { ReactComponent as PakkedLogoFull } from '../assets/icons/pakked-logo-full.svg';
import { ReactComponent as CheckBoxBlankSvg } from '../assets/icons/check-box-blank.svg';
import { ReactComponent as CheckBoxCheckedSvg } from '../assets/icons/check-box-checked.svg';
import { ReactComponent as EditSvg } from '../assets/icons/edit.svg';
import { ReactComponent as MailSvg } from '../assets/icons/mail.svg';
import { ReactComponent as PinSvg } from '../assets/icons/pin.svg';

const iconTypes = {
  approved: createSvgIcon(<ApprovedSvg />, 'Approved'),
  pakkedLogo: createSvgIcon(<PakkedLogoSvg />, 'PakkedLogo'),
  pakkedLogoFull: createSvgIcon(<PakkedLogoFull />, 'PakkedLogoFull'),
  google: createSvgIcon(<GoogleSvg />, 'Google'),
  microsoft: createSvgIcon(<MicrosoftSvg />, 'Microsoft'),
  refresh: createSvgIcon(<RefreshSvg />, 'Refresh'),
  checkBoxBlank: createSvgIcon(<CheckBoxBlankSvg />, 'CheckBoxBlank'),
  checkBoxChecked: createSvgIcon(<CheckBoxCheckedSvg />, 'CheckBoxChecked'),
  edit: createSvgIcon(<EditSvg />, 'Edit'),
  mail: createSvgIcon(<MailSvg />, 'Mail'),
  pin: createSvgIcon(<PinSvg />, 'Pin'),
};

export type AppIconName = keyof typeof iconTypes;

type AppIconProps = {
  name: keyof typeof iconTypes;
  width?: number;
  height?: number;
} & SvgIconProps;

const AppIcon: React.FC<AppIconProps> = ({
  name,
  width = 20,
  height = 20,
  ...props
}) => {
  const Icon = iconTypes[name];
  return <Icon {...props} sx={{ ...props?.sx, width, height }} />;
};

export default AppIcon;
