import { Color } from '@mui/material'

const forest: Partial<Color> = {
  100: '#E1EAE4',
  200: '#D0DED4',
  300: '#B1C8BD',
  400: '#84ADA0',
  500: '#669990',
  600: '#49837E',
  700: '#37635F',
  800: '#24423F',
  900: '#12211F',
}

export default forest
