import { Box, Grid, LinearProgress, styled, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Field, useFormikContext} from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import {redirectPage, sleep} from '../../../utils';
import { FormSubmitButton } from '../../../components/Form';
import {AuthFormFields, AuthUIMode} from '..';
import AppIcon, {AppIconName} from '../../../components/AppIcon';
import { useSearchParams } from "react-router-dom";
import usePostHogCapture from "../../../hooks/usePostHogCapture";

interface AuthUIProps {
  mode: AuthUIMode;
  changeMode: VoidFunction;
}

const GOOGLE_OIDC_URL = '/oidc/login/google';
const MICROSOFT_OIDC_URL = '/oidc/login/microsoft';

const Root = styled(Grid)(({theme}) => ({
  minHeight: '100vh',
})) as typeof Grid

const BgGrid = styled(Grid)(({theme}) => ({
  background: 'url("/assets/login-graphic.png") no-repeat',
  backgroundPositionX: '65%',
  backgroundPositionY: 0,
  backgroundSize: 'cover',
  backgroundColor: theme.palette.surface.primary3,
  [theme.breakpoints.down('md')]: {
    order: -1,
    height:220
  },
})) as typeof Grid

const SignInWithTypography = styled(Typography)(({theme}) => ({
  position: 'relative',
  textAlign: 'center',
  display: 'flex',
  alignItems:'center',

  '&:before,&:after': {
    content: '" "',
    height: 1,
    background: theme.palette.charcoal[200],
    flex:1,
  },
  '&:before': {
    marginRight: theme.spacing(1),
  },
  '&:after': {
    marginLeft: theme.spacing(1),
  },
}));

const OIDCButton = styled(Button)({
  padding: '12px 20px',
});

const labels = {
  signup: {
    bottomTitle: `Already have an account?`,
    bottomButtonText: 'Sign in',
    buttonText: 'Continue',
    oidcTitle: 'Or sign up with',
  },
  login: {
    bottomTitle: `Don't have an account?`,
    bottomButtonText: 'Sign up',
    buttonText: 'Sign in',
    oidcTitle: 'Or sign in with',
  },
};

const PakkedLogo = styled(AppIcon)(({theme}) => ({
  zIndex: 3,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  color: theme.palette.white.main,
  [theme.breakpoints.down('md')]: {
    width: 69,
    height: 68,
  },
}));

const EXCHANGE_TOKEN_URL = '/auth/login/exchange_token';


const AuthUI: React.FC<AuthUIProps> = ({mode, changeMode}) => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const formik = useFormikContext<AuthFormFields>();
  const postHogCapture = usePostHogCapture()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isOutlook = searchParams.has('outlook')
  const modeLabels = labels[mode];

  const [showWelcomeScreen, setShowWelcomeScreen] = useState(isOutlook)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const exchangeToken = async () => {
      const response = await fetch(EXCHANGE_TOKEN_URL, {
        method: 'POST',
        credentials: 'same-origin'
      });

      if (response.status === 200) {
        postHogCapture('user_logged_in', {
          email: formik.values.email
        }, {
          send_instantly: true,
          skip_client_rate_limiting: true
        })
        const body = await response.json()
        await sleep(1000)
        redirectPage(body.redirectUri)
      }
    }

    exchangeToken().finally(() => {
      setIsReady(true)
    })
  }, [formik.values.email, postHogCapture])

  if (!isReady)
    return <LinearProgress/>

  const welcomeBullets: { icon: AppIconName, text: string }[] = [
    {icon: 'pin', text: 'Move attachments easily to Pakked'},
    {icon: 'mail', text: 'Add email messages to existing projects'},
    {icon: 'edit', text: 'Turn important emails into new projects'},
  ]

  const welcomeScreen = (
    <Stack spacing={5}>
      <Stack spacing={2}>
        <Typography variant='h2' color='text.primary3' textAlign='center'>Welcome</Typography>
        <Typography variant='body1' color='text.primary2' textAlign='center'>Maximize your project productivity with
          Pakked for Outlook.</Typography>
      </Stack>
      <Stack spacing={2}>
        {
          welcomeBullets.map(({icon, text}, index) => {
            return (
              <Stack key={index} spacing={1} direction='row' alignItems='center'>
                <AppIcon name={icon} width={24} height={24} sx={{color: theme => theme.palette.text.primary3}}/>
                <Typography variant='body1' color='text.primary1'>{text}</Typography>
              </Stack>
            )
          })
        }
      </Stack>
      <Button variant='contained' size='large' onClick={() => setShowWelcomeScreen(false)}>Get started</Button>
    </Stack>
  )

  const oidc = (
    <Stack direction='row' spacing={2.5} justifyContent='center'>
      <OIDCButton
        variant='outlined'
        startIcon={<AppIcon name="google"/>}
        onClick={() => redirectPage(GOOGLE_OIDC_URL)}
        fullWidth
      >
        Google
      </OIDCButton>
      <OIDCButton
        variant='outlined'
        startIcon={<AppIcon name="microsoft"/>}
        onClick={() => redirectPage(MICROSOFT_OIDC_URL)}
        fullWidth
      >
        Microsoft
      </OIDCButton>
    </Stack>
  );

  const signup = (
    <Stack
      direction='row'
      textAlign='center'
      justifyContent='center'
      alignItems='center'
      spacing={1}
    >
      <Typography color={theme.palette.charcoal[700]}>
        {modeLabels.bottomTitle}
      </Typography>
      <Button variant='text' onClick={changeMode}>
        {modeLabels.bottomButtonText}
      </Button>
    </Stack>
  );

  const header = (
    <Stack textAlign='left' alignSelf='auto' spacing={isSmall ? 2 : 1}>
      {
        isSmall ? <Typography variant='h4' color='text.primary1'>
          Sign in
        </Typography> : <Typography variant='h1' color='text.primary2'>
          Enter your e-mail
        </Typography>
      }

      {
        isSmall ? <Typography variant='body2' color='text.secondary'>
          Enter your e-mail below to authenticate your account.
        </Typography> : <Typography variant='body1' color='text.secondary'>
          Please enter your e-mail below.
        </Typography>
      }
    </Stack>
  );

  const authSection = (
    <Stack spacing={3} paddingTop={isSmall?3:5}>
      <Field
        name='email'
        placeholder='Enter your email'
        label='E-mail'
        component={TextField}
      />
      <FormSubmitButton label={modeLabels.buttonText}/>
      <Stack direction='row' spacing={1.5}>
        <Field name='rememberMe' component={Checkbox} type='checkbox'/>
        <Typography variant='body1'>Remember me</Typography>
      </Stack>
      {!isOutlook && (
        <>
          <SignInWithTypography variant='body1' color={theme.palette.charcoal[700]}>
            {modeLabels.oidcTitle}
          </SignInWithTypography>
          {oidc}
        </>
      )}
      {signup}
    </Stack>
  );

  return (
    <Root container direction={isSmall ? 'column' : 'row'}>
      <Grid
        container
        item
        justifyContent='center'
        alignItems='center'
        md={6}
        padding={{xs: theme.spacing(5, 3), md: 0}}
      >
        <Box maxWidth={isSmall?undefined:320} width='100%' >
          {
            showWelcomeScreen ?
              welcomeScreen :
              <>
                {header}
                {authSection}
              </>
          }

        </Box>
      </Grid>
      <BgGrid item alignItems='center' md={6} position='relative'>
        <PakkedLogo
          name='pakkedLogo'
          width={217}
          height={212}
        />
      </BgGrid>
    </Root>
  );
};

export default AuthUI;
