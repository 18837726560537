import React from 'react'
import { useSearchParams } from 'react-router-dom'
import GeneralError from './general-error'

const Error = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  if (code === 'invalid_token') {
    return (
      <GeneralError
        title="Invalid token"
        subtitle="It seems that your token has expired, please login or sign up again to request a new one."
        buttonText="Return to login"
        buttonHref="/"
      />
    )
  }

  return <GeneralError />
}

export default Error