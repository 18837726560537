import React, {useEffect, useState} from 'react'
import {useSearchParams} from "react-router-dom";
import {LinearProgress, Stack, Typography} from "@mui/material";
import AppIcon from "../../components/AppIcon";
import GeneralError from "../error/general-error";
import {redirectPage} from "../../utils";

interface MagicLinkProps {
}

const VERIFIER_URL = '/auth/login/verifier';


const MagicLink: React.FC<MagicLinkProps> = () => {
  const [searchParams] = useSearchParams();

  const requestId = searchParams.get('request_id')
  const code = searchParams.get('code')

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string>()

  useEffect(() => {
    const verify = async () => {
      const response = await fetch(VERIFIER_URL, {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
          requestId,
          code
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        if (response.status === 204) {
          setSuccess(true)
        } else {
          const body = await response.json()
          redirectPage(body.redirectUri)
        }
      } else {
        let error
        try {
          const body = await response.json()
          if (body.message) {
            error = body.message
          }
        } catch (e) {
          console.error(e)
        }
        setError(error ?? 'Something went wrong! Please try again later.')
      }
    }

    verify()
  }, [])

  if (error) {
    return (
      <GeneralError
        title='Whoops!'
        subtitle={error}
      />
    )
  }

  if (!success) {
    return <LinearProgress/>
  }

  const header = (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      paddingX={5}
      paddingTop={5}
    >
      <AppIcon
        name='pakkedLogoFull'
        width={160}
        height={56}
        viewBox='0 0 160 56'
      />
    </Stack>
  );

  const content = (
    <Stack textAlign='center' alignItems='center' spacing={3}>
      <AppIcon
        name='approved'
        width={90}
        height={90}
      />
      <Stack textAlign='center' spacing={2}>
        <Typography variant='h1' color='text.primary2'>Login successful!</Typography>
        <Typography variant='body1' color='text.secondary'>
          Please close this page and continue with your original login page.
        </Typography>
      </Stack>
    </Stack>
  );


  return (
    <Stack spacing={5}>
      {header}
      {content}
    </Stack>
  );
}

export default MagicLink
