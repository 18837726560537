import * as Yup from 'yup';
import * as Joi from '@hapi/joi';
import { tlds } from '@hapi/tlds';

export const EmailSchema = (isRequired: boolean) => {
  const schema = Yup.string().test(
    'valid-email',
    'Please enter a valid email',
    (email) => {
      const { error } = Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: tlds } })
        .validate(email);

      return !error;
    },
  );
  if (isRequired) {
    return schema.required('E-mail is a required field');
  }
  return schema;
};
