
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthPage, ErrorPage, MagicLinkPage } from './pages';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material';
import theme from './configs/theme';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ToastContainer style={{ width: "400px" }}
            position="top-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        <Router>
          <Routes>
            <Route path="/" element={<AuthPage />}></Route>
            <Route path="/auth/*" element={<AuthPage />}>
            </Route>
            <Route path="/magic-link" element={<MagicLinkPage />}/>
            <Route path="/error" element={<ErrorPage />}></Route>
          </Routes>
        </Router>
      </ThemeProvider>
      </QueryClientProvider>
  );
}

export default App;
