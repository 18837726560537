import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useMediaQuery, useTheme} from '@mui/material';
import AppIcon from '../../components/AppIcon';
import { Image } from '@nextui-org/react';

interface GeneralErrorProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonHref?: string;
}

const GeneralError: React.FC<GeneralErrorProps> = ({
  title = 'Whoops! Page is missing',
  subtitle = 'We could not find what you are looking for. You can either head back to the previous page or go to our homepage.',
  buttonText = 'Return home',
  buttonHref = 'https://pakked.com/',
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const errorInfo = (
    <Stack spacing={2}>
      <Typography variant='h1'>{title}</Typography>
      <Typography variant='body1'>{subtitle}</Typography>
    </Stack>
  );

  return (
    <Stack
      direction='row'
      paddingX={isSmall ? 4 : 19.5}
      spacing={15}
      justifyContent='center'
      alignItems='center'
      height='100vh'
    >
      <Stack spacing={5} justifyContent='center'>
        <AppIcon name="pakkedLogo" width={48} height={47} sx={{ color: theme.palette.primary[700] }} />
        {errorInfo}
        <Button variant='contained' href={buttonHref} sx={{ width: 'fit-content' }} size="large">
          {buttonText}
        </Button>
      </Stack>
      {
        !isSmall && (
          <Box>
            <Image src="/assets/general-error.svg" width={587} height={528}/>
          </Box>
        )
      }
    </Stack>
  );
};

export default GeneralError;
