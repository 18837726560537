import React from 'react';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography, styled } from '@mui/material';

interface FormSubmitButtonProps {
  label: string;
}

const ErrorMessageTypography = styled(Typography)(({ theme }) => ({
  color: '#DE6159',
  marginTop: 8,
}));

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  label,
}) => {
  const { isValid, isSubmitting, handleSubmit, status, dirty } = useFormikContext();

  return (
    <Box>
      <Button
        type='submit'
        disabled={!(isValid && !isSubmitting && dirty)}
        variant='contained'
        size="large"
        fullWidth
        onClick={() => handleSubmit()}
      >
        {label}
      </Button>
      {!!status && (
        <ErrorMessageTypography variant='body2'>
          {status.submit}
        </ErrorMessageTypography>
      )}
    </Box>
  );
};

export default FormSubmitButton;
