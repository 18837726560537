import {useCallback} from "react";
import {usePostHog} from 'posthog-js/react'
import {CaptureOptions, CaptureResult, Properties} from "posthog-js";


const usePostHogCapture = () => {
  const postHog = usePostHog()

  return useCallback((event_name: string, properties?: Properties | null, options?: CaptureOptions): CaptureResult | void => {
    if (!window.pakSetting?.postHog.enabled)
      return

    return postHog?.capture(event_name, properties, options)
  }, [postHog])

}

export default usePostHogCapture