import { Color } from '@mui/material'

const cream: Partial<Color> = {
  600: '#FCF7EE',
  700: '#F9F0DF',
  800: '#F5E5C7',
  900: '#F0D9AD',
}

export default cream
