import { Color } from '@mui/material'

const golden: Partial<Color> = {
  100: '#EEE8DD',
  200: '#E4DBC8',
  300: '#D1BD94',
  400: '#C2A770',
  500: '#B1955D',
  600: '#8A7242',
  700: '#675532',
  800: '#453921',
  900: '#221C11',
}

export default golden
