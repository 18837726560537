import {Route, Routes, useNavigate, useSearchParams} from 'react-router-dom';
import EmailConfirmation from './components/EmailConfirmation';
import {EmailSchema} from '../../utils/yup/EmailSchema';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useRef, useState} from 'react';
import AuthUI from './components/AuthUI';
import usePostHogCapture from "../../hooks/usePostHogCapture";

const initialValues = {
  email: '',
  rememberMe: false,
};

const validationSchema = Yup.object().shape({
  email: EmailSchema(true),
  rememberMe: Yup.bool(),
});

export interface AuthFormFields {
  email: string;
  rememberMe: boolean;
}

const LOGIN_REQUEST_URL = '/auth/login/request';

export type AuthUIMode = 'signup' | 'login';

export default function Auth() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const postHogCapture = usePostHogCapture()
  const redirectUriRef = useRef(searchParams.get('redirect_uri'))
  const [authMode, setAuthMode] = useState<AuthUIMode>('login');

  const handleSubmit = async (
    values: { email: any; rememberMe?: boolean },
    setStatus: (status?: any) => void,
  ) => {
    try {
      const response = await fetch(LOGIN_REQUEST_URL, {
        method: 'POST',
        body: JSON.stringify({
          email: values.email,
          rememberMe: values.rememberMe,
          loginMethod: 'MagicLink',
          redirectUri: redirectUriRef.current,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        let error

        const body = await response.json()
        if (body.message) {
          error = body.message
        }

        setStatus({submit: error ?? 'Something went wrong! Please try again later.'});
      } else {
        navigate('/auth/email-confirmation');
        setStatus({});
        postHogCapture('magic_link_requested',{
          email : values.email
        })
      }
    } catch (e) {
      setStatus({submit: 'Something went wrong! Please try again later.'});
      throw e
    }
  };

  const authUI = (
    <AuthUI
      mode={authMode}
      changeMode={() =>
        authMode === 'signup' ? setAuthMode('login') : setAuthMode('signup')
      }
    />
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, {setStatus}) => handleSubmit(values, setStatus)}
      validationSchema={validationSchema}
    >
      <Routes>
        <Route path='/' element={authUI}/>
        <Route path='/login' element={authUI}/>
        <Route
          path='/email-confirmation'
          element={<EmailConfirmation mode={authMode}/>}
        />
      </Routes>
    </Formik>
  );
}
